<template>
  <img
    :src="`/img/logos/${brand}/${variant}.svg`"
    :alt="`${$t.brand} ${$t.home}`"
    :width="sizes[variant]?.width"
    :height="sizes[variant]?.height"
    :class="{ 'filter invert': invert }"
    data-test-id="vf-logo"
  >
</template>

<script lang="ts" setup>
import type { LogoVariants } from '#types/components/vf/logo'

withDefaults(defineProps<{
  variant?: LogoVariants
  invert?: boolean
}>(), {
  variant: 'default',
  invert: false,
})

const { brand } = useRuntimeConfig().public
const { sizes = {} } = useAppConfig().components.vf.logo || {}
</script>
